import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import IndexPage from '../../pages/index'
import RoadmapPage from '../../pages/roadmap/roadmap'

const AppMain: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<IndexPage />} />
        <Route path={'/roadmap'} element={<RoadmapPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppMain
