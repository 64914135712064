import React from 'react'
import Logo from '../../../components/Logo'
import { Flex } from '@react-css/flex'
import { useMediaQuery } from 'react-responsive'

import {
  Copyright,
  Divider,
  FooterContainer,
  LinkColumnDivider,
  LinkColumnTitle,
  LinkItem,
  Slogan
} from './footer.style'

export type Link = {
  text: string
  to?: string
  icon?: string
  external?: boolean
}

type LinkColumnProps = {
  title: string
  links: Link[]
}

const LogoAndSlogan: React.FC = () => {
  return (
    <Flex column alignItems={'center'}>
      <Logo style={{ marginBottom: '20px' }} />
      <Slogan />
    </Flex>
  )
}

const LinkColumn: React.FC<LinkColumnProps> = ({ title, links }) => {
  return (
    <Flex column>
      <LinkColumnTitle>{title}</LinkColumnTitle>
      <LinkColumnDivider />
      {
        links.map(({ text, icon, to, external }) => (
          <Flex key={text}>
            <LinkItem
              style={{ cursor: !to ? 'not-allowed' : 'pointer' }}
              href={to}
              target={external ? '_blank' : '_self'}
              rel={'noreferrer'}
            >
              {
                icon && <img src={icon} alt={text} />
              }
              {text}
            </LinkItem>
          </Flex>
        ))
      }
    </Flex>
  )
}

const LinksMatrix: React.FC = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

  const linkColumns: LinkColumnProps[] = [
    {
      title: 'Banksea',
      links: [
        { text: 'Home', to: '/' },
        { text: 'Roadmap', to: '/roadmap' },
        { external: true, text: 'Doc', to: 'https://banksea-finance.gitbook.io/banksea-finance/' },
        { external: true, text: 'Medium', to: 'https://medium.com/@BankseaFinance' },
      ]
    },
    {
      title: 'Information',
      links: [
        { text: 'FAQ' },
        { text: 'Blog' },
        { external: true, text: 'Videos', to: 'https://www.youtube.com/channel/UC-XfgMJ9E0SjPHr9dxURdmg' },
      ]
    },
    {
      title: 'Other',
      links: [
        { text: 'Banksea Token' },
        { text: 'Disclaimer' },
        { text: 'Brand Guidelines' },
      ]
    },
    {
      title: 'Social Media',
      links: [
        { external: true, text: 'Twitter', to: 'https://twitter.com/banksea_finance', icon: require('../../../assets/social-media-logos/twitter.svg').default },
        { external: true, text: 'Telegram', to: 'https://t.me/banksea_finance', icon: require('../../../assets/social-media-logos/telegram.svg').default },
        { external: true, text: 'Discord', to: 'https://discord.com/invite/F4AcB4ycCQ', icon: require('../../../assets/social-media-logos/discord.svg').default },
        { external: true, text: 'Github', to: 'https://github.com/Banksea-Finance', icon: require('../../../assets/social-media-logos/github.svg').default },
        { external: true, text: 'Email', to: 'mailto://contact@banksea.finance', icon: require('../../../assets/social-media-logos/email.svg').default },

        // { external: true, text: 'Facebook', to: '/', icon: require('../../../assets/social-media-logos/facebook.svg').default },
      ]
    },
  ]


  return (
    <Flex
      row
      flexWrap={'wrap'}
      justifyContent={'space-between'}
      style={{
        width: !isMobile ? '70%' : '330px',
        marginLeft: !isMobile ? '' : 'calc((100% - 330px) / 2)',
        marginTop: !isMobile ? '' : '54px'
      }}
    >
      {
        linkColumns.map(column => (
          <LinkColumn key={column.title} {...column} />
        ))
      }
    </Flex>
  )
}

const AppFooter: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

  return (
    <FooterContainer>
      <Flex flexDirection={!isMobile ? 'row' : 'column'} justifyContent={'space-between'}>
        <LogoAndSlogan />
        <LinksMatrix />
      </Flex>
      <Divider />
      <Copyright />
    </FooterContainer>
  )
}

export default AppFooter
