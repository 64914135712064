import { useEffect, useState } from 'react'
import { News } from '../pages/index/modules/news/news'

const useBankseaMediumPublicationsQuery = () => {
  const [publications, setPublications] = useState<News[]>([])

  useEffect(() => {
    (
      async () => {
        try {
          const json = await (await fetch('https://api.allorigins.win/get?url=https://medium.com/@BankseaFinance/feed')).json()
          const { contents } = json

          const dom = new window.DOMParser().parseFromString(contents, 'text/xml')
          const items = dom.querySelectorAll('item')

          const parsedItems: News[] = []

          items.forEach(item => {
            const pubDate = item.querySelector('pubDate')!.textContent!
            const encoded = item.querySelector('encoded')!.textContent!
            const encodedDom = new window.DOMParser().parseFromString(encoded, 'text/html')

            parsedItems.push({
              content: encoded.replace(/<[^>]+>/g,'').slice(0, 200),
              date: pubDate.slice(pubDate.indexOf(', ') + 2, pubDate.length - 13),
              imageUrl: encodedDom.querySelector('img')!.getAttribute('src')!,
              title: item.querySelector('title')!.textContent!,
              link: item.querySelector('link')!.textContent!
            })
          })

          setPublications(parsedItems.slice(0, 3))
        } catch (e) {
          console.error(e)
        }
      }
    )()
  }, [])

  return publications
}

export default useBankseaMediumPublicationsQuery
