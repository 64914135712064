import styled from 'styled-components'

export const FooterContainer = styled.div`
  background-color: rgba(23, 25, 30);
  padding-left: calc((100% - ${props => props.theme.screenWidth}) / 2);
  padding-right: calc((100% - ${props => props.theme.screenWidth}) / 2);
  padding-top: 37px;
  padding-bottom: 14px;

  border-top: 1px solid rgba(255, 255, 255, 0.2);;
`

export const Slogan = styled.div`
  width: 241px;
  font-size: 20px;
  line-height: 38px;
  color: #FFFFFF;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.2);

  &:before {
    content: 'Securely and Fast, Financialize your NFT.';
  }
  
  @media screen and (max-width: 900px) {
    text-align: center;
  }
`

export const LinkColumnTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;

  color: #FFFFFF;

  -webkit-text-stroke: 1px solid rgba(255, 255, 255, 0.2);
`

export const LinkColumnDivider = styled.div`
  width: 82px;
  height: 2px;
  background: linear-gradient(90deg, #64CDD7 0%, rgba(100, 205, 215, 0) 100%);
  box-sizing: border-box;
  margin-top: 12px;
  margin-bottom: 22px;
`

export const LinkItem = styled.a`
  color: #BABAC0;
  margin-bottom: 25px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  
  img {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    padding: 3px;

    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 21px;
  }
`

export const Divider = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  margin-bottom: 23px;
  margin-top: 20px;
`

export const Copyright = styled.div`
  width: 100%;
  text-align: center;
  font-family: 'Gilroy';
  font-size: 14px;
  line-height: 30px;
  color: #A5A5A5;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.2);

  &:before {
    content: '© 2021 Banksea - All right reserved';
  }
`
