import styled from 'styled-components'
import { ModuleTitle } from '../../../../styles/components/title'

export const NewsModuleContainer = styled.div`
  max-width: ${p => p.theme.screenWidth};
  position: relative;
  margin-bottom: 68px;
`

export const Title = styled(ModuleTitle)`
  margin-bottom: 50px;

  &:before {
    content: 'News';
  }
`

export const ViewAllLink = styled.a`
  position: absolute;
  right: 0;
  top: 0;
  height: fit-content;
  transition: 0.8s;

  img {
    width: 172px;
  }
  
  &:hover {
    right: -20px;
  }
  
  @media screen and (max-width: 900px) {
    top: 50px;
    
    img {
      width: 120px;
    }
  }
`

export const NewsCard = styled.a`
  width: 32%;
  height: fit-content;
  letter-spacing: 0.01em;
  background: #181B20;
  border-radius: 35px;
  margin-bottom: 20px;
  overflow: hidden;
  //transition: 0.8s;
  //
  //&:hover {
  //  box-shadow: 0 0 5px 10px rgba(255,255,255,0.2);
  //}
  
  img {
    width: 100%;
    height: 268px;
    object-fit: cover;
    border-bottom: 1px solid white;
  }
  
  .main {
    padding: 33px 30px 50px 30px;
    
    .title {
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 10px;
      height: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
    .content {
      display: block;
      font-family: Gilroy;
      font-size: 18px;
      line-height: 30px;
      color: #BABAC0;
      margin-bottom: 40px;
      word-wrap: break-word;
      height: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
    .author {
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 10px;
    }
    
    .from {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #BABAC0;
    }
    
    .date {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #BABAC0;
    }
  }
  
  @media screen and (max-width: 900px) {
    width: 325px;
    margin-left: calc((100% - 325px) / 2);

    img {
      height: 176px;
    }
    
    .main {
      padding: 21px 18px 31px 18px;
      
      .title {
        font-size: 15px;
      }
      
      .content {
        font-size: 12px;
      }
      
      .date {
        font-size: 12px;
      }
    }
  }
`
