import styled from 'styled-components'

export const RoadmapContainer = styled.div`
  padding-top: 148px;
  position: relative;
`

export const Title = styled.div`
  font-weight: bold;
  font-size: 72px;
  line-height: 100%;
  text-align: center;
  margin-bottom: 87px;
  
  &:before {
    content: 'Roadmap';
  }
  
  @media screen and (max-width: 900px) {
    font-size: 30px;
  }
`

export const RoadmapImage = styled.img`
  width: 1379px;
  margin-left: calc((100vw - 1379px) / 2);
  margin-bottom: 165px;
  
  @media screen and (max-width: 900px) {
    width: 350px;
    margin-left: calc((100% - 350px) / 2);
  }
`
