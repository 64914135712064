import React from 'react'
import { Title, TokenUtilityModuleContainer } from './token-utility.style'
import { Flex } from '@react-css/flex'
import { useMediaQuery } from 'react-responsive'

export type TokenUtility = {
  image: string
  text: string
}

const tokenUtilities: TokenUtility[] = [
  {
    image: require('../../../../assets/modules/token-utility/share-platform-revenue.png').default,
    text: 'Share platform revenue'
  },
  {
    image: require('../../../../assets/modules/token-utility/participate-in-governance.png').default,
    text: 'Participate in governance'
  },
  {
    image: require('../../../../assets/modules/token-utility/repurchase-and-burn.png').default,
    text: 'Repurchase and burn'
  },
  {
    image: require('../../../../assets/modules/token-utility/earn-ai-oracle-gas-fee.png').default,
    text: 'Earn AI Oracle gas fee'
  },
]

const TokenUtilityModule: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

  return (
    <TokenUtilityModuleContainer data-aos="fade-in">
      <Title />
      {
        !isMobile ?
          <Flex row justifySpaceBetween alignItemsCenter  className={'row'}>
            {
              tokenUtilities.map(utility => (
                <Flex column alignItemsCenter key={utility.text}>
                  <img src={utility.image} alt={utility.text} />
                  <span>{utility.text}</span>
                </Flex>
              ))
            }
          </Flex> :
          <Flex column justifySpaceBetween alignItemsCenter  className={'row'}>
            {
              tokenUtilities.map(utility => (
                <Flex column alignItemsCenter key={utility.text} style={{ marginTop: '73px' }}>
                  <img src={utility.image} alt={utility.text} />
                  <span>{utility.text}</span>
                </Flex>
              ))
            }
          </Flex>
      }
    </TokenUtilityModuleContainer>
  )
}

export default TokenUtilityModule
