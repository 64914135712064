import React from 'react'
import { FeaturesModuleContainer, LendingMain, Title } from './features.style'
import lendingImg from '../../../../assets/modules/features/lendingImg.png'
import listIcon from '../../../../assets/modules/features/listIcon.png'
import { useMediaQuery } from 'react-responsive'

type Feature = {
  img: any
  text: string
}

const FeaturesModule: React.FC = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

  const lendingItem: Feature[] = [
    {
      img: listIcon,
      text: 'Deposit / Withdraw timely'
    },
    {
      img: listIcon,
      text: 'Full risk control system '
    },
    {
      img: listIcon,
      text: 'Take loans / Redeem timely '
    },
    {
      img: listIcon,
      text: 'Liquidation Mechanism'
    },
    {
      img: listIcon,
      text: 'Security fund pool to insure lending fund pool '
    }
  ]

  return (
    <FeaturesModuleContainer data-aos="fade-in">
      {
        !isMobile ?
          <div style={{ display: 'flex' }}>
            <img className="features-img" src={lendingImg} alt={''} />
            <LendingMain>
              <Title>NFT Pool-Based Lending</Title>
              <div className="features">
                Features:
              </div>
              {
                lendingItem.map(({ img, text }, index) => (
                  <div className="lending-item" key={index}>
                    <img src={`${img}`} alt={text} data-aos="flip-left" />
                    <span>{text}</span>
                  </div>
                ))
              }
            </LendingMain>
          </div> :
          <div>
            <LendingMain>
              <Title>NFT Pool-Based <br /> Lending</Title>
              <div className="features">
                Features:
              </div>
              {
                lendingItem.map(({ img, text }, index) => (
                  <div className="lending-item" key={index}>
                    <img src={`${img}`} alt={text} data-aos="flip-left" />
                    <span>{text}</span>
                  </div>
                ))
              }
            </LendingMain>
            <img className="features-img" src={lendingImg} alt={''} />
          </div>
      }
    </FeaturesModuleContainer>
  )
}

export default FeaturesModule
