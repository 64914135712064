import React, { CSSProperties } from 'react'
import { InvestorsModuleContainer, Title } from './investors.style'
import { Flex } from '@react-css/flex'
import { useMediaQuery } from 'react-responsive'

export type Investor = {
  image: string
  url: string
  height: CSSProperties['height']
}

const InvestorsModule: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

  const investors: Investor[] = [
    {
      image: require('../../../../assets/modules/investors/parrot.png').default,
      url: 'https://parrot.fi',
      height: !isMobile ? '120px' : '60px'
    },
    {
      image: require('../../../../assets/modules/investors/shima.png').default,
      url: 'https://shima.capital/',
      height: !isMobile ? '150px' : '80px'
    },
    {
      image: require('../../../../assets/modules/investors/petrock.png').default,
      url: 'https://www.petrock.capital/',
      height: !isMobile ? '160px' : '100px'
    },
    {
      image: require('../../../../assets/modules/investors/mexc.svg').default,
      url: 'https://www.mexc.com/',
      height: !isMobile ? '40px' : '20px'
    },
    {
      image: require('../../../../assets/modules/investors/solar.png').default,
      url: 'https://www.solarecofund.com/',
      height: !isMobile ? '180px' : '80px'
    },
    {
      image: require('../../../../assets/modules/investors/springwind.svg').default,
      url: '',
      height: !isMobile ? '120px' : '80px'
    },
    {
      image: require('../../../../assets/modules/investors/rio.png').default,
      url: 'https://riochain.io/',
      height: !isMobile ? '150px' : '80px'
    },
    {
      image: require('../../../../assets/modules/investors/digital.png').default,
      url: 'https://www.drf.ee/',
      height: !isMobile ? '100px' : '50px'
    },
    {
      image: require('../../../../assets/modules/investors/xt.png').default,
      url: 'https://www.xt.com/labs',
      height: !isMobile ? '150px' : '80px'
    },
    {
      image: require('../../../../assets/modules/investors/ventures.png').default,
      url: 'https://gtaventures.org/',
      height: !isMobile ? '70px' : '30px'
    },
    {
      image: require('../../../../assets/modules/investors/zonff.png').default,
      url: 'https://www.zonff.partners',
      height: !isMobile ? '130px' : '80px'
    },
    {
      image: require('../../../../assets/modules/investors/definitive.png').default,
      url: 'https://www.definitive.capital/',
      height: !isMobile ? '70px' : '40px'
    },
    {
      image: require('../../../../assets/modules/investors/da.png').default,
      url: 'https://edigital-assets.io/',
      height: !isMobile ? '100px' : '60px'
    },
    {
      image: require('../../../../assets/modules/investors/investment.png').default,
      url: 'https://solution.ventures/',
      height: !isMobile ? '100px' : '60px'
    },
    {
      image: require('../../../../assets/modules/investors/palar.png').default,
      url: 'https://palar.capital/',
      height: !isMobile ? '90px' : '60px'
    },
    {
      image: require('../../../../assets/modules/investors/inv.png').default,
      url: 'https://inv.ventures/',
      height: !isMobile ? '140px' : '90px'
    },
    {
      image: require('../../../../assets/modules/investors/catcher.png').default,
      url: 'https://catchervc.chaincatcher.com',
      height: !isMobile ? '40px' : '20px'
    },
  ]

  return (
    <InvestorsModuleContainer>
      <Title />
      <Flex row justifySpaceBetween flexWrap={'wrap'} alignItemsCenter className={'row-1'}>
        {
          investors.map(investor => (
            <a href={investor.url} key={investor.url} target={'_blank'} rel="noreferrer">
              <img src={investor.image} alt="" style={!isMobile ? { height: investor.height, padding: '40px 30px' } : { height: investor.height, padding: '40px 5px' }} data-aos="zoom-in" />
            </a>
          ))
        }
      </Flex>
    </InvestorsModuleContainer>
  )
}

export default InvestorsModule
