import React from 'react'
import 'atropos/atropos.css'
// @ts-ignore
import { Atropos } from 'atropos/react/atropos-react.esm'

import { FeaturesContainer, FeaturesMain, Title } from './main-features.style'
import item1 from '../../../../assets/modules/main-features/featuresItemAI.png'
import item2 from '../../../../assets/modules/main-features/featuresItemDec.png'
import item3 from '../../../../assets/modules/main-features/featuresItemRisk.png'
import item4 from '../../../../assets/modules/main-features/featuresItemSca.png'
import item1Mobile from '../../../../assets/modules/main-features/featuresItemAIMobile.png'
import item2Mobile from '../../../../assets/modules/main-features/featuresItemDecMobile.png'
import item3Mobile from '../../../../assets/modules/main-features/featuresItemRiskMobile.png'
import item4Mobile from '../../../../assets/modules/main-features/featuresItemScaMobile.png'

import { useMediaQuery } from 'react-responsive'

type features = {
  img: string
  title: string
  text: string
  top: string
}

const featuresItem: features[] = [
  {
    img: item1,
    title: 'The first AI-driven \n NFT Oracle',
    text: 'Evaluate NFT from full aspects',
    top: '250px'
  },
  {
    img: item2,
    title: 'Decentralized',
    text: 'Distributed AI nodes deployment',
    top: '230px'
  },
  {
    img: item3,
    title: 'Risk control',
    text: 'Timely and reliable risk control',
    top: '230px'
  },
  {
    img: item4,
    title: 'Scalable',
    text: 'Multi-chain deployment and \n' + 'Whitelist expansion',
    top: '250px'
  }
]

const featuresItemMobile: features[] = [
  {
    img: item1Mobile,
    title: 'The first AI-driven \n NFT Oracle',
    text: 'Evaluate NFT from full \n aspects',
    top: '110px'
  },
  {
    img: item2Mobile,
    title: 'Decentralized',
    text: 'Distributed AI nodes \n deployment',
    top: '110px'
  },
  {
    img: item3Mobile,
    title: 'Risk control',
    text: 'Timely and reliable risk \n control',
    top: '110px'
  },
  {
    img: item4Mobile,
    title: 'Scalable',
    text: 'Multi-chain deployment and \n' + 'Whitelist expansion',
    top: '110px'
  }
]

const MainFeaturesModule: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

  return (
    <FeaturesContainer data-aos="fade-in">
      <Title />
      {
        !isMobile ?
          <FeaturesMain>
            {
              featuresItem.map(({ img, title, text, top }, index) => (
                <Atropos key={index}>
                  <div className="features-item" >
                    <img src={img} alt={text} />
                    <div className="item-data" style={{ top: `${top}` }}>
                      <div className="item-title">{title}</div>
                      <div className="item-text">{text}</div>
                    </div>
                  </div>
                </Atropos>
              ))
            }
          </FeaturesMain> :
          <FeaturesMain>
            {
              featuresItemMobile.map(({ img, title, text, top }, index) => (
                <Atropos key={index}>
                  <div className="features-item" >
                    <img src={img} alt={text} />
                    <div className="item-data" style={{ top: `${top}` }}>
                      <div className="item-title">{title}</div>
                      <div className="item-text">{text}</div>
                    </div>
                  </div>
                </Atropos>
              ))
            }
          </FeaturesMain>
      }
    </FeaturesContainer>
  )
}

export default MainFeaturesModule
