import styled from 'styled-components'
import { ModuleTitle } from '../../../../styles/components/title'

export const FeaturesContainer = styled.div`
  width: 100%;
  height: 900px;
  max-height: 900px;
  background: url(${require('../../../../assets/modules/main-features/subtract.png').default}) no-repeat;
  background-size: cover;
  position: relative;
  bottom: 80px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media screen and (max-width: 900px) {
    height: 589px;
    background: url(${require('../../../../assets/modules/main-features/subtractMobile.png').default});
    background-size: 100%;
    bottom: 0;
  }
`

export const Title = styled(ModuleTitle)`
  margin-top: 90px;
  position: relative;
  z-index: 3;

  &:before {
    content: 'Main Features';
  }

  @media screen and (max-width: 900px) {
    margin-top: 0;
  }
`

export const FeaturesMain = styled.div`
  z-index: 3;
  position: relative;
  margin-top: 30px;
  width: ${p => p.theme.screenWidth};
  margin-left: calc((100% - ${props => props.theme.screenWidth}) / 2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .features-item {
    position: relative;
    text-align: center;
    
    img {
      width: 300px;
    }
    
    .item-data {
      width: 100%;
      position: absolute;
      white-space: pre;
    }
    
    .item-title {
      line-height: 39px;
      font-size: 22px;
      font-weight: bolder;
      color: #FAFAFA
    }
    
    .item-text {
      width: 100%;
      //margin-top: 0px;
      font-size: 16px;
      color: #BABAC0;
      line-height: 31px
    }
  }
  
  @media screen and (max-width: 900px) {
    width: 330px;
    margin-left: calc((100% - 330px) / 2);
    flex-wrap: wrap;

    .features-item {
      position: relative;
      text-align: center;
      margin-top: 20px;

      img {
        width: 100%;
      }

      .item-data {
        width: 100%;
        position: absolute;
        top: 0;
        white-space: pre;
      }

      .item-title {
        line-height: 15px;
        font-size: 12px;
        font-weight: bolder;
        color: #FAFAFA
      }

      .item-text {
        width: 100%;
        font-size: 11px;
        color: #BABAC0;
        line-height: 15px;
        margin-top: 10px;
      }
    }
  }
`

