import React, { useState } from 'react'
import Logo from '../../../components/Logo'
import { AppHeaderContainer, LinksContainer, MenuMobile, MenuMobileContent } from './header.style'
import { Link } from '../footer/footer'
import { useMediaQuery } from 'react-responsive'
import menuMobile from '../../../assets/modules/header/menuMobile.png'

const AppHeader: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

  const [menu, setMenu] = useState<string>('none')

  const links: Link[] = [
    {
      text: 'Roadmap',
      to: '/roadmap'
    },
    {
      text: 'Medium',
      to: 'https://medium.com/@BankseaFinance',
      external: true
    },
    {
      text: 'Doc',
      to: 'https://banksea-finance.gitbook.io/banksea-finance/',
      external: true
    },
    {
      text: 'CitizenOne',
      to: 'https://nft.banksea.finance',
      external: true
    },
  ]

  const showMenu = () => {
    menu == 'none' ? setMenu('block') : setMenu('none')
  }


  return (
    <AppHeaderContainer>
      <Logo />
      {
        !isMobile
          ? (
            <LinksContainer>
              {
                links.map(({ text, to, external }) => (
                  <a key={text} href={to} target={external ? '_blank' : '_self'} rel="noreferrer">
                    {text}
                  </a>
                ))
              }
            </LinksContainer>)
          : (
            <MenuMobile>
              <img src={menuMobile} alt="" onClick={showMenu} />
            </MenuMobile>
          )
      }
      <MenuMobileContent style={{ display: `${menu}` }}>
        <LinksContainer>
          {
            links.map(({ text, to, external }) => (
              <a key={text} href={to} target={external ? '_blank' : '_self'} rel="noreferrer">
                {text}
              </a>
            ))
          }
        </LinksContainer>
      </MenuMobileContent>
    </AppHeaderContainer>
  )
}

export default AppHeader
