import React from 'react'
import AppLayout from './layout'
import 'aos/dist/aos.css'
import AOS from 'aos'

const App = () => {

  AOS.init({
    duration: 800,
    easing: 'ease-in-sine',
    delay: 0,
  })

  return (
    <div className="App">
      <AppLayout />
    </div>
  )
}

export default App
