import React, { CSSProperties } from 'react'
import { PartnershipsModuleContainer, Title } from './partnerships.style'
import { Flex } from '@react-css/flex'
import { useMediaQuery } from 'react-responsive'

export type Partnership = {
  image: string
  url: string
  text?: string
  height: CSSProperties['height']
}

const PartnershipsModule: React.FC = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

  const partnerships: Partnership[] = [
    {
      image: require('../../../../assets/modules/partnerships/parrot.png').default,
      url: 'https://parrot.fi',
      height: !isMobile ? '82px' : '40px'
    },
    {
      image: require('../../../../assets/modules/partnerships/moonbeam.png').default,
      url: 'https://moonbeam.network/',
      height: !isMobile ? '84px' : '40px'
    },
    {
      image: require('../../../../assets/modules/partnerships/slope.png').default,
      url: 'https://slope.finance/',
      height: !isMobile ? '60px' : '30px'
    },
    {
      image: require('../../../../assets/modules/partnerships/solanart.png').default,
      url: 'https://solanart.io/collections/citizenone',
      text: 'Solanart',
      height: !isMobile ? '60px' : '30px'
    },
    {
      image: require('../../../../assets/modules/partnerships/ME.svg').default,
      url: 'https://magiceden.io/marketplace/citizenone',
      height: !isMobile ? '60px' : '30px'
    },
    {
      image: 'https://solsea.io/assets/solsea_Logo_RGB_final1.svg',
      url: 'https://solsea.io/collection/61f56d3d6f589f689cbe1c18',
      height: !isMobile ? '50px' : '30px'
    },
  ]

  return (
    <PartnershipsModuleContainer>
      <Title />
      <Flex
        row
        alignItemsCenter
        justifyCenter
        flexWrap={'wrap'}
        style={ !isMobile ? { width: '1440px' } : { width: '100%' }}
      >
        {
          partnerships.map(p => (
            <a href={p.url} key={p.url} target={'_blank'} rel="noreferrer">
              <img src={p.image} alt="" style={{ height: p.height }} data-aos="zoom-in" />
              <span>{p?.text}</span>
            </a>
          ))
        }
      </Flex>

    </PartnershipsModuleContainer>
  )
}

export default PartnershipsModule
