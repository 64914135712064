import styled from 'styled-components'

export const ModuleTitle = styled.div`
  font-weight: bold;
  font-size: 45px;
  line-height: 56px;
  letter-spacing: 0.01em;
  text-align: center;
  
  @media screen and (max-width: 900px) {
    font-size: 30px;
  }
`
