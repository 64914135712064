import React from 'react'
import { RoadmapContainer, RoadmapImage, Title } from './roadmap.style'

const RoadmapPage: React.FC = () => {
  return (
    <RoadmapContainer>
      <Title />
      <RoadmapImage src={require('../../assets/roadmap.webp').default} />
    </RoadmapContainer>
  )
}

export default RoadmapPage
