import React from 'react'
import { HomeContainer, Launch } from './home.style'
import star from '../../../../assets/modules/home/star.png'
import { useMediaQuery } from 'react-responsive'
import solanaLogo from '../../../../assets/modules/home/solana.png'
import polkadotLogo from '../../../../assets/modules/home/polkadot.png'
import ethereumLogo from '../../../../assets/modules/home/ethereum.png'
import binanceLogo from '../../../../assets/modules/home/binance.png'
import separate from '../../../../assets/modules/home/separate.png'


const HomeModule: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

  return (
    <HomeContainer>
      <div className="left-area">
        {
          !isMobile ?
            <div className="left-area-title">
              NFT Price Discovery Protocol<br />
              & Pool-based Lending Hub
            </div> :
            <div className="left-area-title">
              NFT Price Discovery<br />
              Protocol & Pool-<br />
              based Lending Hub
            </div>
        }
        {
          !isMobile
            ? <div className="left-area-text">Convenient NFT collateralized loan to keep your funds fully liquid</div>
            : <div className="left-area-text">Convenient NFT collateralized loan to<br /> keep your funds fully liquid</div>
        }
        <Launch>
          <a href="https://oracle.banksea.finance" target={'_blank'} rel="noreferrer">
            <div className="btn flash-slide flash-slide--blue">NFT Oracle</div>
          </a>
          <a href="https://app.banksea.finance" target={'_blank'} rel="noreferrer">
            <div className="btn flash-slide flash-slide--pink" style={{ marginLeft: '50px' }}>Pre-staking</div>
          </a>
        </Launch>

        <a href="https://devpost.com/software/banksy-finance" target="_blank" rel="noreferrer" >
          <div className="winner">
            <img src={star} alt="" />
            <span>Winner in Solana Global Ignition Hackathon</span>
          </div>
        </a>
        <div className="chains">
          <img src={solanaLogo} alt="" />
          <img className="separate" src={separate} alt="" />
          <img src={polkadotLogo} alt="" />
          <img className="separate" src={separate} alt="" />
          <img src={ethereumLogo} alt="" />
          <img className="separate" src={separate} alt="" />
          <img src={binanceLogo} alt="" />
        </div>
      </div>
      {
        !isMobile ?
          <div /> :
          <img className="home-right-image" src={require('../../../../assets/modules/home/NFTMobile.png').default} alt="" />
      }
    </HomeContainer>
  )
}

export default HomeModule
