import styled from 'styled-components'
import { ModuleTitle } from '../../../../styles/components/title'

export const PartnershipsModuleContainer = styled.div`
  height: 438px;
  margin-bottom: 89px;
  width: ${p => p.theme.screenWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
  
  //img {
  //  height: 122px;
  //}
  
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;
    margin-left: 80px;
    
    span {
      margin-left: 10px;
      font-size: 30px;
      font-family: -apple-system;
    }

    @media screen and (max-width: 900px) {
      margin-left: 20px;
    }
  }
  
  @media screen and (max-width: 900px) {
    width: 330px;
  }
`

export const Title = styled(ModuleTitle)`
  margin-bottom: 60px;

  &:before {
    content: 'Partnerships';
  }
`
