import styled from 'styled-components'

export const HomeContainer = styled.div`
  height: 1080px;
  width: 100%;
  background: url(${require('../../../../assets/modules/home/background.png').default}) no-repeat;
  background-size: 100%;
  z-index: 99;
  position: relative;

  .left-area {
    z-index: 10;
    position: absolute;
    top: 250px;
    left: calc((100% - ${props => props.theme.screenWidth}) / 2);

    .left-area-build {
      width: fit-content;
      font-size: 18px;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(178deg, #95E1FF, #4086D1, #EC489C, #FFCA3D);
      font-weight: bolder;
    }

    .left-area-title {
      margin-top: 22px;
      font-size: 46px;
      font-weight: bolder;
      line-height: 75px;
      letter-spacing: 0.01em;
      
      @media only screen and (max-width: 1690px) {
        font-size: 40px;
      }
    }

    .left-area-text {
      margin-top: 18px;
      font-family: Gilroy;
      font-size: 24px;
      font-weight: bolder;

      @media only screen and (max-width: 1690px) {
        font-size: 20px;
      }
    }

    .btn:hover .btn-slide-show-text1 {
      margin-left: 65px;
    }

    .flash-slide {
      border: none;
      display: inline-block;
      width: 230px;
      height: 65px;
      line-height: 65px;
      margin-top: 36px;
      border-top-left-radius: 20px;
      border-bottom-right-radius: 20px;
      position: relative;
      overflow: hidden;
      font-family: Gilroy;
      font-size: 20px;
      text-align: center;
    }

    .flash-slide::before {
      content: "";
      position: absolute;
      top: -30px;
      left: -80px;
      height: 120px;
      width: 70px;
      background: rgba(255, 255, 255, 0.4);
      transform: rotate(20deg);
    }

    .flash-slide:hover::before {
      left: 240px;
      transition: all 0.5s ease 0s;
    }

    .flash-slide--blue {
      background: #64CDD7;
      font-weight: bolder;
    }
    .flash-slide--pink {
      background: #e8bff1;
      font-weight: bolder;
    }

    .winner {
      width: fit-content;
      height: 48px;
      padding: 15px;
      font-size: 24px;
      font-family: Gilroy;
      font-weight: bolder;
      margin-top: 121px;
      display: flex;
      align-items: center;
      border-radius: 15px;
      background: rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(10px);

      img {
        width: 48px;
        margin-right: 8px;
      }
    }

    .chains {
      width: 663px;
      margin-top: 95px;
      display: flex;
      align-items: center;
      
      img:nth-last-of-type(1) {
        height: 40px;
      }
      
      .separate {
        height: 40.8px;
        margin: 0 10px;
      }
    }
  }

  .home-right-image {
    position: absolute;
    top: 25%;
    right: 8%;
    z-index: 2;
  }
  
  @media Screen and (max-width: 900px) {
    height: 900px;
    background: url(${require('../../../../assets/modules/home/backgroundMobile.png').default}) no-repeat;
    background-size: cover;

    .left-area {
      z-index: 10;
      position: absolute;
      top: 90px;
      left: 5%;

      .left-area-build {
        font-size: 12px;
      }

      .left-area-title {
        margin-top: 22px;
        font-size: 28px;
        line-height: 140%;
      }

      .left-area-text {
        margin-top: 10px;
        font-size: 18px;
        line-height: 170%;
      }

      .btn:hover .btn-slide-show-text1 {
        margin-left: 65px;
      }

      .flash-slide {
        width: 140px;
        height: 45px;
        line-height: 45px;
        margin-top: 31px;
        font-size: 18px;
      }

      .winner {
        width: fit-content;
        padding: 10px;
        font-size: 12px;
        font-family: Gilroy;
        font-weight: bolder;
        margin-top: 35px;

        img {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
      }

      .chains {
        width: 330px;
        margin-top: 23px;

        img {
          height: 18px;
        }
        
        img:nth-last-of-type(1) {
          height: 18px;
        }

        .separate {
          height: 40.8px;
          margin: 0 10px;
        }
      }
    }

    .home-right-image {
      width: 350px;
      position: absolute;
      top: 600px;
      right: calc((100% - 350px) / 2);
      z-index: 2;
    }
  }
`

export const BackgroundImage = styled.img`
  width: 100vw;
  //max-height: 1030px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: cover;
`

export const Launch = styled.div`
  display: flex;
`
