import React, { CSSProperties } from 'react'
import { useMediaQuery } from 'react-responsive'


const Logo: React.FC<{ style?: CSSProperties }> = ({ style }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

  return (
    <a href="/">
      <img
        style={!isMobile ? { width: '264px', objectFit: 'fill', ...style }: { width: '162.68px', objectFit: 'fill', ...style }}
        src={require('../assets/logo.svg').default}
        alt="Banksea"
      />
    </a>
  )
}

export default Logo
