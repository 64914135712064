import React from 'react'
import HomeModule from './modules/home/home'
import NewsModule from './modules/news/news'
import { Flex } from '@react-css/flex'
import PartnershipsModule from './modules/partnerships/partnerships'
import InvestorsModule from './modules/investors/inverstor'
import TokenUtilityModule from './modules/token-utility/token-utility'
import MainFeaturesModule from './modules/main-features/main-features'
import FeaturesModule from './modules/features/features'

const IndexPage: React.FC = () => {
  return (
    <Flex column alignItemsCenter>
      <HomeModule />
      <MainFeaturesModule />
      <FeaturesModule />
      <TokenUtilityModule />
      <InvestorsModule />
      <PartnershipsModule />
      <NewsModule />

    </Flex>
  )
}

export default IndexPage
