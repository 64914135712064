import styled from 'styled-components'
import { ModuleTitle } from '../../../../styles/components/title'

export const InvestorsModuleContainer = styled.div`
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .row-1 {
    width: 1440px;
    margin-bottom: 55px;
  }
  
  @media screen and (max-width: 900px) {
    .row-1 {
      width: 350px;
      margin-bottom: 55px;
    }
  }
`

export const Title = styled(ModuleTitle)`
  margin-bottom: 78px;
  
  &:before {
    content: 'Investors';
  }
`
