import styled from 'styled-components'
import { ModuleTitle } from '../../../../styles/components/title'

export const TokenUtilityModuleContainer = styled.div`
  margin-bottom: 200px;
  
  .row {
    width: ${p => p.theme.screenWidth};
  }
  
  img {
    margin-bottom: 40px;
    width: 176px;
    transition: 0.8s;
    
    &:hover {
      transform: rotatey(180deg);
    }
  }
  
  span {
    font-size: 20px;
  }
  
  @media screen and (max-width: 900px) {
    .row {
      width: 330px;
      margin-left: calc((100% - 330px) / 2);
      flex-wrap: wrap;
    }
  }
`

export const Title = styled(ModuleTitle)`
  margin-bottom: 116px;
  
  &:before {
    content: 'Token Utility';
  }
  
  @media screen and (max-width: 900px) {
    margin-bottom: 31px;
  }
`
