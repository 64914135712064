import React from 'react'
import AppHeader from './components/header/header'
import AppFooter from './components/footer/footer'
import AppMain from './components/main'
import styled from 'styled-components'

const AppLayoutContainer = styled.div`
  //background: skyblue;
  background-image: url(${require('../assets/background.png').default});
  background-size: cover;
  background-repeat: no-repeat;

  //@media screen and (max-width: 900px) {
  //  background-image: none;
  //}
`

const AppLayout: React.FC = () => {

  return (
    <AppLayoutContainer>
      <AppHeader />
      <AppMain />
      <AppFooter />
    </AppLayoutContainer>
  )
}

export default AppLayout
