import styled from 'styled-components'
import { ModuleTitle } from '../../../../styles/components/title'

export const FeaturesModuleContainer = styled.div`
  display: flex;
  padding-bottom: 150px;
  
  .features-img {
    margin-top: -100px;
  }
  
  @media screen and (max-width: 900px) {
    width: 330px;
    padding-top: 80px;
    padding-bottom: 50px;
    display: block;
    position: relative;

    .features-img {
      width: 330px;
      margin-left: calc((100% - 330px) / 2);
      margin-top: 50px;
     }
  }
`

export const LendingMain = styled.div`
  
  .features {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    margin-top: 10px;
    letter-spacing: 0.01em;
    color: rgba(0, 221, 246, 0.96);
  }
  
  .lending-item {
    margin-top: 10px;
    font-size: 24px;
    line-height: 37px;
    color: #FFFFFF;
    
    span {
      font-family: Gilroy;
      margin-left: 15px;
    }
  }

  @media screen and (max-width: 900px) {
    .features {
      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
      margin-top: 10px;
      letter-spacing: 0.01em;
      color: rgba(0, 221, 246, 0.96);
    }

    .lending-item {
      margin-top: 10px;
      font-size: 24px;
      line-height: 37px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      
      img {
        width: 20px;
      }

      span {
        font-family: Gilroy;
        margin-left: 12px;
        font-size: 18px;
      }
    }
  }
`

export const Title = styled(ModuleTitle)`
  width: 500px;
  text-align: left;
  
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`

