import React from 'react'
import { NewsCard, NewsModuleContainer, Title, ViewAllLink } from './news.style'
import { Flex } from '@react-css/flex'
import useBankseaMediumPublicationsQuery from '../../../../hooks/useBankseaMediumPublicationsQuery'
import { useMediaQuery } from 'react-responsive'

export type News = {
  imageUrl: string
  title: string
  content: string
  author?: string
  from?: string
  date: string
  link: string
}

const NewsModule: React.FC = () => {
  const publications = useBankseaMediumPublicationsQuery()

  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

  if (!publications.length) {
    return <></>
  }

  return (
    <NewsModuleContainer>
      <Title />
      <ViewAllLink href={'https://medium.com/@BankseaFinance'} target={'_blank'} rel={'noreferrer'}>
        <img src={require('../../../../assets/modules/news/view-all.svg').default} alt="" />
      </ViewAllLink>

      <Flex flexDirection={!isMobile ? 'row' : 'column'} justifySpaceBetween flexWrap={'wrap'} alignItemsEnd>
        {
          publications.map(({ title, content, author, from, date, imageUrl, link }, index) => (
            <NewsCard key={index} href={link} target={'_blank'}>
              <img src={imageUrl} alt={title} />
              <div className="main">
                <div className="title">{title}</div>
                <div className="content">{content}</div>

                <Flex row justifySpaceBetween alignItemsEnd>
                  <div>
                    <div className="author">{author}</div>
                    <div className="from">{from}</div>
                  </div>
                  <div className="date">{date}</div>
                </Flex>
              </div>
            </NewsCard>
          ))
        }
      </Flex>
    </NewsModuleContainer>
  )
}

export default NewsModule
