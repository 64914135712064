import styled from 'styled-components'

export const AppHeaderContainer = styled.div`
  position: absolute;
  width: ${props => props.theme.screenWidth};
  left: calc((100% - ${props => props.theme.screenWidth}) / 2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  z-index: 999;
  
  @media screen and (max-width: 900px) {
    width: 90%;
    left: 5%;
  }
`

export const LinksContainer = styled.div`
  display: flex;
  width: 435px;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 20px;
  
  @media screen and (max-width: 900px) {
    flex-direction: column;
    width: 100%;
    padding: 10px;
    
    a {
      width: fit-content;
      height: 30px;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(178deg, #95E1FF, #4086D1, #EC489C, #FFCA3D);
      font-weight: bolder;
    }
  }
`

export const MenuMobile = styled.div`

`

export const MenuMobileContent = styled.div`
  width: 100%;
  height: fit-content;
  background: rgba(255,255,255,0.8);
  position: absolute;
  top: 51px;
  left: 0;
`
